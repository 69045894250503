@import '../../../scss/color_vars.scss';

.loader{
	min-height: 100%;

	div {
	// > div {

		// &:before {
			// content: '';
			animation-duration: 0.5s;
			animation-name: background_showing;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $dark_color;
			// background: #1b3548;
			// background: -webkit-gradient(radial, center center, 120, center center, 900, from(#1c2937), to(#1b3548));
			// background:-moz-radial-gradient(circle, #1c2937, #1b3548);
			transform-origin: 100% 100%;
			z-index: 10;
		// }

		span {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			display: inline-block;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -10px;
			animation:  0.5s infinite linear;
			-webkit-animation: 0.5s infinite linear;
			-moz-animation: 0.5s infinite linear;
			-o-animation: 0.5s infinite linear;
		}

		span:nth-child(1) {
			background: #6ebc3b;
			animation: kiri 1.2s infinite linear;
			-webkit-animation: kiri 1.2s infinite linear;
			-moz-animation: kiri 1.2s infinite linear;
			-o-animation: kiri 1.2s infinite linear;
			z-index: 99;
		}

		span:nth-child(2) {
			background: #f6a821;
			z-index: 100;
		}

		span:nth-child(3) {
			background: #39a3f4;
			animation: kanan 1.2s infinite linear;
			-webkit-animation: kanan 1.2s infinite linear;
			-moz-animation: kanan 1.2s infinite linear;
			-o-animation: kanan 1.2s infinite linear;
			z-index: 99;
		}
	}
}

.light_mode{
	.loader{
		// > div {
		div {

			// &:before {
				background-color: white;
			// }
		}
	}
}

// blue
@keyframes kanan {	// right (indonesian)
	0% {
		-webkit-transform:translateX(20px);
	}

	40% {
		z-index: 99;
	}

	50% {
		-webkit-transform:translateX(-20px);
	}

	100% {
		-webkit-transform:translateX(20px);
		z-index: 101;
	}
}
@-webkit-keyframes kanan {
	0% {
		-webkit-transform:translateX(20px);
	}

	40% {
		z-index: 99;
	}

	50% {
		-webkit-transform:translateX(-20px);
	}

	100% {
		-webkit-transform:translateX(20px);
		z-index: 101;
	}
}
@-moz-keyframes kanan {
	0% {
		-moz-transform:translateX(20px);
	}

	40% {
		z-index: 99;
	}

	50% {
		-moz-transform:translateX(-20px);
	}

	100% {
		-moz-transform:translateX(20px);
		z-index: 101;
	}
}
@-o-keyframes kanan {
	0% {
		-o-transform:translateX(20px);
	}

	40% {
		z-index: 99;
	}

	50%{
		-o-transform:translateX(-20px);
	}

	100%{
		-o-transform:translateX(20px);
		z-index: 101;
	}
}

// green
@keyframes kiri {	// left (indonesian)
	0% {
		-webkit-transform:translateX(-20px);
		z-index: 101;
	}
	40% {
		z-index: 101;
	}
	50% {
		-webkit-transform:translateX(20px);
	}
	100% {
		-webkit-transform:translateX(-20px);
	}
}
@-webkit-keyframes kiri {
	0% {
		-webkit-transform:translateX(-20px);
		z-index: 101;
	}
	40% {
		z-index: 101;
	}
	50% {
		-webkit-transform:translateX(20px);
	}
	100% {
		-webkit-transform:translateX(-20px);
	}
}
@-moz-keyframes kiri {
	0% {
		-moz-transform:translateX(-20px);
		z-index: 101;
	}
	40% {
		z-index: 101;
	}
	50% {
		-moz-transform:translateX(20px);
	}
	100% {
		-moz-transform:translateX(-20px);
	}
}
@-o-keyframes kiri {
	0% {
		-o-transform:translateX(-20px);
		z-index: 101;
	}
	40% {
		z-index: 101;
	}
	50% {
		-o-transform:translateX(20px);
	}
	100% {
		-o-transform:translateX(-20px);
	}
}
