@import '../../../scss/color_vars.scss';

.Header{
	padding: 15px 80px 0px 80px;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid $light_gray_color;
	height: 57px;

	.Header_logo{
		height: 38px;
		width: 105px;
		margin-right: 50px;
		cursor: pointer;
		transition: all .4s ease-in;
		overflow: hidden;
		z-index: 5;
	}

	.logo_opened{
		margin-right: 50px !important;
		width: 105px !important;
	}

	.Header_page_current{
		cursor: default;
	}

	.Header_page_current, .Header_page{
		margin-left: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 40px;
		padding-bottom: 15px;
		border-bottom: 2px solid transparent;   // or bottom border 'jumping'
	}

	.Header_page_current, .Header_page:hover{
		color: $green_color;
		border-bottom: 2px solid $green_color;
	}

	.Header_color_trigger{
		margin: 5px 20px 0 auto;
	}

	.Header_account_block{
		height: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// word-wrap: break-word;
		white-space: pre-wrap;
		font-size: 14px;
	}

	.Header_mobile_menu_button{
		display: none;
		margin-left: auto;
		z-index: 5;

		.menu_path, .close_path{
			transition: opacity 0.5s;
		}

		.menu_on, .close_on{
			opacity: 1;
		}

		.menu_off, .close_off{
			opacity: 0;
		}
	}

	.Header_mobile_menu{
		display: none;
		position: fixed;
		top: -101%;
		left: 0;
		width: 100%;
		height: 100%;
		transition: 0.5s;
		opacity: 0;
		// transition-timing-function: cubic-bezier();
		z-index: 3;

		.Header_mobile_menu_content{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 100px 20px 80px 20px;
			height: calc(100% - 180px);
			position: relative;

			.mobile_menu_account_block{
				display: flex;
				flex-direction: row;
				align-items: center;

				.mobile_menu_account_block_text{
					font-size: 20px;
				}

				.button_margin{
					margin-left: 20px;
				}
			}

			.mobile_menu_links_block{
				display: flex;
				flex-direction: column;
				font-size: 25px;

				.Header_page_current_mobile, .Header_page_mobile{
					margin-top: 10px;
					margin-bottom: 10px;
					text-align: center;
					border-bottom: 2px solid transparent;   // or bottom border 'jumping'
				}

				.Header_page_current_mobile{
					cursor: default;
				}

				.Header_page_current_mobile, .Header_page_mobile:hover{
					color: $green_color;
					border-bottom: 2px solid $green_color;
				}
			}

			.mobile_menu_switchers{
				display: flex;
				flex-direction: column;
				align-items: center;

				.triggers_divider{
					height: 20px;
				}
			}
		}
	}

	.mobile_menu_opened{
		top: 0;
		opacity: 1;
	}
}

.Header_replacer{
	height: 73px;
}

.dark_mode{

	.Header{

		.Header_logo{
			background: url('../../../assets/hedget-logo-dark.png');
			background-size: 105px 38px;
			background-repeat: no-repeat;
		}

		.Header_account_block{
			color: white;
		}

		.Header_mobile_menu_button{

			svg{
				fill: white;
			}
		}

		.Header_mobile_menu{
			background-color: $dark_color;
			border-bottom: 8px solid rgb(90, 90, 90);
		}
	}
}

.light_mode{

	.Header{

		.Header_logo{
			background: url('../../../assets/hedget-logo-light.png');
			background-size: 105px 38px;
			background-repeat: no-repeat;
		}

		.Header_account_block{
			color: $dark_gray_color;
		}

		.Header_mobile_menu_button{

			svg{
				fill: $gray_color_2;
			}
		}

		.Header_mobile_menu{
			background-color: white;
			border-bottom: 8px solid $light_gray_color; // or add shadow
		}
	}
}

@media screen and (max-width: 900px) { // 900 - 0
	.Header{
		padding: 15px 20px 0 20px;

		.Header_logo{
			margin-right: 0px;
			width: 43px;
		}

		.Header_page, .Header_page_current, .Header_account_block, .Header_color_trigger{
			display: none;
		}

		.Header_mobile_menu_button, .Header_mobile_menu{
			display: block;
		}
	}

	.Header_mobile_fixed{
		position: fixed;
		top: 0;
		width: calc(100% - 40px);
		z-index: 1;
	}
}

@media screen and (max-width: 385px) {
	.Header{
		justify-content: space-evenly;

		.Header_mobile_menu{

			.Header_mobile_menu_content{

				.mobile_menu_account_block{
					flex-direction: column;
				}
			}
		}

		.Header_page, .Header_page_current{
			margin-left: 4px;
		}

		.Header_account_block{
			margin-left: 4px;
		}
	}
}