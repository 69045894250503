@import './color_vars.scss';

body{
	margin: 0;
	font-family: "Poppins", Arial, sans-serif;
	transition: all .5s;

	a{
		text-decoration: none;
	}

	#WEB3_CONNECT_MODAL_ID{     // wallet_connect_modal

		.jYxAGf{
			z-index: 300 !important;
		}
	}
}

.dark_mode{
	color: white;
	background-color: $dark_color;

	a{
		color: white;
	}
}

.light_mode{
	color: black;
	background-color: white;

	a{
		color: #000;
	}
}

// for modals
.closing_bg{
	animation-duration: 0.25s !important;
	animation-name: background_hiding !important;
}

.closing_modal{
	animation-duration: 0.25s !important;
	animation-name: modal_hiding !important;
}

@keyframes modal_showing {
	from{
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
}

@keyframes modal_hiding {
	from{
		transform: scale(1);
	}

	to {
		transform: scale(0);
	}
}

@keyframes background_showing {
	from{
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes background_hiding {
	from{
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}