$red_color: #ED6F47;
$cinnabar_color: #B01D44;
$text_red_color: #da5750;

$green_color: #1DB064;
$text_green_color: #7aae1a;
$pale_green_color: #e9f8f0;

$blue_color: #4486ff;
$light_blue_color: #1c96f2;

$gray_color: #a8a8a8;
$gray_color_2: #949494;
$light_gray_color: #e6edef;
$glue_color: #6c757d;
$dark_gray_color: #495057;
$text_gray_color: #7b7b7b;
$gray_blue_color: #edf2f5;

$yellow_color: #f3c742;

$dark_color: #494949;