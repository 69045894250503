@import '../../../scss/color_vars.scss';

.button_green, .button_green_outline, .button_red, .button_red_outline, .button_blue, .button_blue_outline{
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;

	&:hover{
		// box-shadow: 2px 2px rgba( #000000, .5);
		box-shadow: 0 0 4px rgba(0, 0, 0, 50%);
	}
}

.button_green{
	background-color: $green_color;
	color: #fff;
}

.button_green_outline{
	// background-color: #fff;
	background-color: transparent;
	color: $green_color;
	border: 1px solid $green_color;
}

.button_red{
	background-color: $red_color;
	color: #fff;
}

.button_red_outline{
	// background-color: #fff;
	background-color: transparent;
	color: $red_color;
	border: 1px solid $red_color;
}

.button_blue{
	background-color: $blue_color;
	color: #fff;
}

.button_blue_outline{
	// background-color: #fff;
	background-color: transparent;
	color: $blue_color;
	border: 1px solid $blue_color;
}



.button_blocked, .button_blocked_outline{
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: $gray_color;
	color: #fff;
}

.button_blocked_outline{
	border: 1px solid $gray_color;
}